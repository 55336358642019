var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      attrs: { title: _vm.formTitle, size: "50%", visible: _vm.formVisible },
      on: {
        "update:visible": function($event) {
          _vm.formVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            model: _vm.dataForm,
            "label-width": "120px",
            rules: _vm.formRules
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "显示序号:", prop: "Order" } },
            [
              _c("el-input-number", {
                model: {
                  value: _vm.dataForm.Order,
                  callback: function($$v) {
                    _vm.$set(_vm.dataForm, "Order", $$v)
                  },
                  expression: "dataForm.Order"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "训练名称:", prop: "Name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入名称" },
                model: {
                  value: _vm.dataForm.Name,
                  callback: function($$v) {
                    _vm.$set(_vm.dataForm, "Name", $$v)
                  },
                  expression: "dataForm.Name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "训练频度:", prop: "Objective" } },
            [
              _c("el-input", {
                attrs: {
                  type: "text",
                  "auto-complete": "off",
                  placeholder: "请输入训练频度",
                  disabled: _vm.readOnly
                },
                model: {
                  value: _vm.dataForm.FrequecyRequire,
                  callback: function($$v) {
                    _vm.$set(_vm.dataForm, "FrequecyRequire", $$v)
                  },
                  expression: "dataForm.FrequecyRequire"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "训练目的:", prop: "Objective" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  "auto-complete": "off",
                  placeholder: "请输入训练目的",
                  disabled: _vm.readOnly
                },
                model: {
                  value: _vm.dataForm.Objective,
                  callback: function($$v) {
                    _vm.$set(_vm.dataForm, "Objective", $$v)
                  },
                  expression: "dataForm.Objective"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "动作要领:", prop: "ActionDesc" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  "auto-complete": "off",
                  placeholder: "请输入动作要领或注意事项",
                  disabled: _vm.readOnly
                },
                model: {
                  value: _vm.dataForm.ActionDesc,
                  callback: function($$v) {
                    _vm.$set(_vm.dataForm, "ActionDesc", $$v)
                  },
                  expression: "dataForm.ActionDesc"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "训练器材:", prop: "Equipment" } },
            [
              _c("el-input", {
                attrs: {
                  "auto-complete": "off",
                  placeholder: "请输入训练目的",
                  disabled: _vm.readOnly
                },
                model: {
                  value: _vm.dataForm.Equipment,
                  callback: function($$v) {
                    _vm.$set(_vm.dataForm, "Equipment", $$v)
                  },
                  expression: "dataForm.Equipment"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "训练负荷:", prop: "ActionLoad" } },
            [
              _c("el-input", {
                attrs: {
                  "auto-complete": "off",
                  placeholder: "请输入训练负荷",
                  disabled: _vm.readOnly
                },
                model: {
                  value: _vm.dataForm.ActionLoad,
                  callback: function($$v) {
                    _vm.$set(_vm.dataForm, "ActionLoad", $$v)
                  },
                  expression: "dataForm.ActionLoad"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "运动总量:", prop: "TimeMin" } },
            [
              _c("el-input-number", {
                staticStyle: { width: "40%" },
                attrs: {
                  "auto-complete": "off",
                  placeholder: "分钟",
                  disabled: _vm.readOnly
                },
                model: {
                  value: _vm.dataForm.TimeMin,
                  callback: function($$v) {
                    _vm.$set(_vm.dataForm, "TimeMin", $$v)
                  },
                  expression: "dataForm.TimeMin"
                }
              }),
              _vm._v("分 "),
              _c("el-input-number", {
                staticStyle: { width: "40%" },
                attrs: {
                  "auto-complete": "off",
                  placeholder: "秒",
                  disabled: _vm.readOnly
                },
                model: {
                  value: _vm.dataForm.TimeSec,
                  callback: function($$v) {
                    _vm.$set(_vm.dataForm, "TimeSec", $$v)
                  },
                  expression: "dataForm.TimeSec"
                }
              }),
              _vm._v("秒 ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "视频地址:", prop: "Video" } },
            [
              _c("el-input", {
                attrs: {
                  "auto-complete": "off",
                  placeholder: "视频地址",
                  disabled: _vm.readOnly
                },
                model: {
                  value: _vm.dataForm.Video,
                  callback: function($$v) {
                    _vm.$set(_vm.dataForm, "Video", $$v)
                  },
                  expression: "dataForm.Video"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "示例图片:", prop: "Image" } },
            [
              _vm.dataForm.Image
                ? _c("img", {
                    key: _vm.dataForm.Image,
                    staticStyle: { height: "100px" },
                    attrs: { src: _vm.baseUrl + _vm.dataForm.Image }
                  })
                : _vm._e(),
              _c(
                "el-button",
                {
                  staticClass: "submit-btn",
                  attrs: { type: "warning", size: "mini" },
                  on: { click: _vm.selectFile }
                },
                [_vm._v("点此上传")]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "启用:", prop: "Enabled" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.dataForm.Enabled,
                  callback: function($$v) {
                    _vm.$set(_vm.dataForm, "Enabled", $$v)
                  },
                  expression: "dataForm.Enabled"
                }
              })
            ],
            1
          ),
          !_vm.readOnly
            ? _c("el-form-item", [
                _c(
                  "div",
                  { staticClass: "footer" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.handleSubmit }
                      },
                      [_vm._v("提交")]
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ],
        1
      ),
      _c("fileUploadNoUI", {
        ref: "fileUploadNoUI",
        on: { uploadSuccess: _vm.uploadSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }