<template>
  <el-drawer :title="formTitle" size="50%" :visible.sync="formVisible">
    <el-form
      :model="dataForm"
      ref="dataForm"
      label-width="120px"
      :rules="formRules"
    >
      <el-form-item label="显示序号:" prop="Order">
        <el-input-number v-model="dataForm.Order"></el-input-number>
      </el-form-item>
      <el-form-item label="训练名称:" prop="Name">
        <el-input v-model="dataForm.Name" placeholder="请输入名称"></el-input>
      </el-form-item>
      <el-form-item label="训练频度:" prop="Objective">
        <el-input
          type="text"
          v-model="dataForm.FrequecyRequire"
          auto-complete="off"
          placeholder="请输入训练频度"
          :disabled="readOnly"
        ></el-input>
      </el-form-item>

      <el-form-item label="训练目的:" prop="Objective">
        <el-input
          type="textarea"
          v-model="dataForm.Objective"
          auto-complete="off"
          placeholder="请输入训练目的"
          :disabled="readOnly"
        ></el-input>
      </el-form-item>
      <el-form-item label="动作要领:" prop="ActionDesc">
        <el-input
          type="textarea"
          v-model="dataForm.ActionDesc"
          auto-complete="off"
          placeholder="请输入动作要领或注意事项"
          :disabled="readOnly"
        ></el-input>
      </el-form-item>
      <el-form-item label="训练器材:" prop="Equipment">
        <el-input
          v-model="dataForm.Equipment"
          auto-complete="off"
          placeholder="请输入训练目的"
          :disabled="readOnly"
        ></el-input>
      </el-form-item>
      <el-form-item label="训练负荷:" prop="ActionLoad">
        <el-input
          v-model="dataForm.ActionLoad"
          auto-complete="off"
          placeholder="请输入训练负荷"
          :disabled="readOnly"
        ></el-input>
      </el-form-item>
      <el-form-item label="运动总量:" prop="TimeMin">
        <el-input-number
          v-model="dataForm.TimeMin"
          style="width: 40%"
          auto-complete="off"
          placeholder="分钟"
          :disabled="readOnly"
        ></el-input-number
        >分
        <el-input-number
          v-model="dataForm.TimeSec"
          style="width: 40%"
          auto-complete="off"
          placeholder="秒"
          :disabled="readOnly"
        ></el-input-number
        >秒
      </el-form-item>
      <el-form-item label="视频地址:" prop="Video">
        <el-input
          v-model="dataForm.Video"
          auto-complete="off"
          placeholder="视频地址"
          :disabled="readOnly"
        ></el-input>
      </el-form-item>
      <el-form-item label="示例图片:" prop="Image">
        <img
          v-if="dataForm.Image"
          :src="baseUrl + dataForm.Image"
          :key="dataForm.Image"
          style="height: 100px"
        />
        <el-button
          class="submit-btn"
          type="warning"
          size="mini"
          @click="selectFile"
          >点此上传</el-button
        >
      </el-form-item>
      <el-form-item label="启用:" prop="Enabled">
        <el-switch v-model="dataForm.Enabled"/>
      </el-form-item>
      <el-form-item v-if="!readOnly">
        <div class="footer">
          <el-button type="primary" @click="handleSubmit">提交</el-button>
        </div>
      </el-form-item>
    </el-form>
    <fileUploadNoUI ref="fileUploadNoUI" @uploadSuccess="uploadSuccess" />
  </el-drawer>
</template>
<script>
import { save } from "@/api/library/prescriptionItem";
import enumSelector2 from "@/components/enumSelector2";
import fileUploadNoUI from "@/components/fileUploadNoUI";
export default {
  components: {
    enumSelector2,
    fileUploadNoUI,
  },
  props: {},
  data() {
    return {
      readOnly: false,
      formVisible: false,
      formTitle: "新增",
      dataForm: {
        Order: 0,
        Type: "",
        Image: "",
      },
      formRules: {
        Name: [
          { required: true, message: "请输入训练名称", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    selectFile() {
      this.$refs.fileUploadNoUI.chooseFile();
    },
    uploadSuccess(val) {
      this.dataForm.Image = val.Path;
      //   this.attachments.Items.push(val);
      //   this.$emit("uploaded", { bussiness: this.bussiness, file: val });
    },
    handleView(row) {
      this.readOnly = true;
    },
    handleCreate(typKey) {
      this.formTitle = "新增";
      this.formVisible = true;
      this.readOnly = false;
      this.dataForm = {
        Order: 1,
        Prescription: { Key: typKey },
        Image: "",
        Enabled:true,
      };
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    handleUpdate(row) {
      this.dataForm = JSON.parse(JSON.stringify(row));
      this.readOnly = false;
      this.formTitle = "修改";
      this.formVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    handleSubmit: function () {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let data = Object.assign({}, this.dataForm);
          save(data).then((response) => {
            if (response) {
              this.formVisible = false;
              this.$emit("update");
            }
          });
        } else {
          setTimeout(() => {
            this.$refs["dataForm"].clearValidate();
          }, 1000);
        }
      });
    },
  },
  mounted() {},
};
</script>
<style lang='scss' scoped>
/deep/.el-drawer__header {
  margin-bottom: 10px;
  font-size: 16px;
  color: #ff862f;
}

/deep/.el-drawer__body {
  overflow-y: scroll;
}

.footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

.invoice-container {
  padding: 10px;

  .input {
    width: 150px;
  }

  .tabs-container {
    /deep/.el-tabs--border-card > .el-tabs__content {
      padding: 0;
      height: 1px;
    }

    .tab-content {
      margin-top: -2px;
      border: 1px solid #dcdfe6;
      border-top: 0;
      padding: 15px;
      background: #fff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
    }
  }
}
</style>