import request from '@/utils/request'


export function save(data) {
  return request({
    url: 'prescriptionItem/save',
    method: 'post',
    data
  })
}

export function deleteObj(data) {
  return request({
    url: 'prescriptionItem/delete',
    method: 'post',
    data
  })
}

